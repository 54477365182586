
/* navigation -----------------------------------------*/

.navigation-post {
    $this: &;
    display: flex;
    width: 100%;
    grid-gap: 2px;
    font-size: 0.9em;
    margin:10px 0;

    &__info {
        font-size: 0.9em;
        font-weight: bold;
        margin-bottom: 6px;
    }

    &__item {
        flex: 1;
        padding: 14px 16px;
        background: #fff;
        &.next_post {
            text-align: right;
            a {
                justify-content: flex-end;
                &:after {
                    content: "\f105";
                }
            }
        }
        &.previous_post {
            a {
                justify-content: flex-start;
                &:before {
                    content: "\f104";
                }
            }
        }
    }
    &__link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-gap: 14px;
        width: 100%;
        position: relative;
        &:before,
        &:after {
            font-size: 2em;
            display: flex;
            align-items: center;
            font-family: "FontAwesome";
        }
    }
    &__title {
        position: relative;
        flex: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-weight: bold;
        font-size: 0.9em;
    }
    &__thumb {
        position: relative;
        width: 100px;
        background-color: #eee;
        &:before {
            content: "";
            padding-top: 61.8%;
            display: block;
        }
        amp-img,
        img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    @include mq-down(md) {
        flex-direction: column;
    }
}
