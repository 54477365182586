/* tab -----------------------------------------*/

.widget.widget_diver_widget_tab {
    padding: 0;
    .widget-inner {
        padding: 0;
    }
}

.diver-tabwidget {
    width: 100%;
    &__tabs {
        display: flex;
        overflow-x: auto;
        &--item {
            flex: 1 0 auto;
            padding: 0.6em 1.2em;
            background: #f9f9f9;
            color: #999;
            border-top: 3px solid #aaa;
            font-size: 0.9em;
            white-space: nowrap;
            text-align: center;
            box-shadow: 0 -5px 15px -5px rgb(0 0 0 / 10%) inset;
            cursor: pointer;
            &.is-active {
                background: #fff;
                color: #333;
                border-top: 3px solid #2196f3;
                box-shadow: none;
                cursor: default;
            }
        }
    }
    &__content {
        &--title {
            display: none;
        }
        &--inner {
            width: 100%;
            display: none;
            padding: 10px;
            &:first-of-type {
                display: block;
            }
        }

        ul li a{
            display: block;
            padding: .8em 1em;
            margin: 0;
            position: relative;
            font-size: .9em;
        }
    }
}


#wp-calendar {
    width: 100%;
  }
  
  #wp-calendar caption {
      background: #fff;
      padding: 5px;
  }
  
  #wp-calendar td {
      padding: 5px 0;
      text-align: center;
  }
  
  #wp-calendar thead th{
      padding: 5px;
      text-align: center;
      font-weight: normal;
      border: 1px solid #ccc;
      background: #f9f9f9;
  }